import React from 'react'
import styled from 'styled-components'

export type WebhcatButtonProps = {
    //
}

export const StyledWebchatContainer = styled.div`

  display: flex;
  justify-content: flex-end;
  margin-bottom :10px;
`

function handleShow() {
  if(document.getElementById('cisco_bubble_chat')) {
    const x = document.querySelector('#webchat_button')
    /** @ts-ignore */
    x.style.display = 'none'
  }
}

export const WebchatButton: React.FC<WebhcatButtonProps> = ({ ...props }) => {
  return (
    <StyledWebchatContainer onClick={handleShow} id="webchat_container" dangerouslySetInnerHTML={{ __html: `
      <button id="webchat_button" onclick="ciscoBubbleChat.showChatWindow()"><div id="webchat_image"></div></button>
    ` }}/> 
  )
}