import React, { ComponentProps } from 'react'

import { IconComponent, SvgIconComponent, ImgIconComponent } from './CreditUnionIcon/iconComponentType'
import dynamic from 'next/dynamic'
import analytics from './CreditUnionIcon/icons/analytics.svg'
import calculator from './CreditUnionIcon/icons/calculator.svg'
import brokenBar from './CreditUnionIcon/icons/broken-bar.svg'
import money from './CreditUnionIcon/icons/money.svg'
import piggy from './CreditUnionIcon/icons/piggy.svg'
import umbrella from './CreditUnionIcon/icons/umbrella.svg'
import wallet from './CreditUnionIcon/icons/wallet.svg'
import contact from './CreditUnionIcon/icons/contact.svg'
import chat from './CreditUnionIcon/icons/chat.svg'
import findus from './CreditUnionIcon/icons/findus.svg'
import mobilelending from './CreditUnionIcon/icons/mobilelending.svg'
import nofee from './CreditUnionIcon/icons/nofee.svg'
import clock from './CreditUnionIcon/icons/clock.svg'
import visacard from './CreditUnionIcon/icons/visacard.svg'
import caravan from './CreditUnionIcon/icons/caravan.svg'
import calendar from './CreditUnionIcon/icons/calendar.svg'
import car from './CreditUnionIcon/icons/car.svg'
import home from './CreditUnionIcon/icons/home.svg'
import monitor from './CreditUnionIcon/icons/monitor.svg'
import invest from './CreditUnionIcon/icons/invest.svg'
import ship from './CreditUnionIcon/icons/ship.svg'
import circlestar from './CreditUnionIcon/icons/circlestar.svg'
import analyticsAlt from './CreditUnionIcon/icons/analyticsalt.svg'
import calculatorAlt from './CreditUnionIcon/icons/calculatoralt.svg'
import moneyAlt from './CreditUnionIcon/icons/moneyalt.svg'
import piggyAlt from './CreditUnionIcon/icons/piggyalt.svg'
import umbrellaAlt from './CreditUnionIcon/icons/umbrellaalt.svg'
import walletAlt from './CreditUnionIcon/icons/walletalt.svg'
import contactAlt from './CreditUnionIcon/icons/contactalt.svg'
import chatAlt from './CreditUnionIcon/icons/chatalt.svg'
import findusAlt from './CreditUnionIcon/icons/findusalt.svg'
import mobilelendingAlt from './CreditUnionIcon/icons/mobilelendingalt.svg'
import nofeeAlt from './CreditUnionIcon/icons/nofeealt.svg'
import clockAlt from './CreditUnionIcon/icons/clockalt.svg'
import visacardAlt from './CreditUnionIcon/icons/visacardalt.svg'
import caravanAlt from './CreditUnionIcon/icons/caravanalt.svg'
import calendarAlt from './CreditUnionIcon/icons/calendaralt.svg'
import carAlt from './CreditUnionIcon/icons/caralt.svg'
import homeAlt from './CreditUnionIcon/icons/homealt.svg'
import monitorAlt from './CreditUnionIcon/icons/monitoralt.svg'
import investAlt from './CreditUnionIcon/icons/investalt.svg'
import shipAlt from './CreditUnionIcon/icons/shipalt.svg'
import circlestarAlt from './CreditUnionIcon/icons/circlestaralt.svg'
import blankMonitor from './CreditUnionIcon/icons/blankmonitor.svg'
import blankMonitorAlt from './CreditUnionIcon/icons/blankmonitoralt.svg'
import document from './CreditUnionIcon/icons/document.svg'
import documentAlt from './CreditUnionIcon/icons/documentalt.svg'
import dollar from './CreditUnionIcon/icons/dollar.svg'
import dollarAlt from './CreditUnionIcon/icons/dollaralt.svg'
import down from './CreditUnionIcon/icons/down.svg'
import downAlt from './CreditUnionIcon/icons/downalt.svg'
import envelope from './CreditUnionIcon/icons/envelope.svg'
import envelopeAlt from './CreditUnionIcon/icons/envelopealt.svg'
import hand from './CreditUnionIcon/icons/hand.svg'
import handAlt from './CreditUnionIcon/icons/handalt.svg'
import headphones from './CreditUnionIcon/icons/headphones.svg'
import headphonesAlt from './CreditUnionIcon/icons/headphonesalt.svg'
import lock from './CreditUnionIcon/icons/lock.svg'
import lockAlt from './CreditUnionIcon/icons/lockalt.svg'
import percentage from './CreditUnionIcon/icons/percentage.svg'
import percentageAlt from './CreditUnionIcon/icons/percentagealt.svg'
import profile from './CreditUnionIcon/icons/profile.svg'
import profileAlt from './CreditUnionIcon/icons/profilealt.svg'
import shield from './CreditUnionIcon/icons/shield.svg'
import shieldAlt from './CreditUnionIcon/icons/shieldalt.svg'
import unlock from './CreditUnionIcon/icons/unlock.svg'
import unlockAlt from './CreditUnionIcon/icons/unlockalt.svg'
import up from './CreditUnionIcon/icons/up.svg'
import upAlt from './CreditUnionIcon/icons/upalt.svg'
import wrench from './CreditUnionIcon/icons/wrench.svg'
import wrenchAlt from './CreditUnionIcon/icons/wrenchalt.svg'
import australia from './CreditUnionIcon/icons/australia.svg'
import australiaAlt from './CreditUnionIcon/icons/australiaalt.svg'
import mobilelocation from './CreditUnionIcon/icons/mobilelocation.svg'
import mobilelocationAlt from './CreditUnionIcon/icons/mobilelocationalt.svg'

//Mapping of iconCode to Icon Function Component
type CreditUnionIconMapType = Record<string, {
  svg?: React.ComponentType<ComponentProps<SvgIconComponent>>
  img?: typeof analytics
}>

export const CreditUnionIconMap: CreditUnionIconMapType = {
  'australia': {
    img: australia,
  },
  'australiaalt': {
    img: australiaAlt,
  },
  'analytics': {
    img: analytics,
  },
  'analyticsalt': {
    img: analyticsAlt,
  },
  'blankmonitor': {
    img: blankMonitor,
  },
  'blankmonitoralt': {
    img: blankMonitorAlt,
  },
  'calculator': {
    img: calculator,
  },
  'calculatoralt': {
    img: calculatorAlt,
  },
  'calendar': {
    img: calendar,
  },
  'calendaralt': {
    img: calendarAlt,
  },
  'car': {
    img: car,
  },
  'caralt': {
    img: carAlt,
  },
  'caravan': {
    img: caravan,
  },
  'caravanalt': {
    img: caravanAlt,
  },
  'chat': {
    img: chat,
  },
  'chatalt': {
    img: chatAlt,
  },
  'circlestar': {
    img: circlestar,
  },
  'circlestaralt': {
    img: circlestarAlt,
  },
  'clock': {
    img: clock,
  },
  'clockalt': {
    img: clockAlt,
  },
  'contact': {
    img: contact,
  },
  'contactalt': {
    img: contactAlt,
  },
  'document': {
    img: document,
  },
  'documentalt': {
    img: documentAlt,
  },
  'double-traingle': {
    svg: dynamic(() => import('./CreditUnionIcon/DoubleTriangle').then(mod => mod.DoubleTriangle)),
  },
  'double-trainglealt': {
    svg: dynamic(() => import('./CreditUnionIcon/DoubleTriangle').then(mod => mod.DoubleTriangle)),
  },
  'dollar': {
    img: dollar,
  },
  'dollaralt': {
    img: dollarAlt,
  },
  'down': {
    img: down,
  },
  'downalt': {
    img: downAlt,
  },
  'envelope': {
    img: envelope,
  },
  'envelopealt': {
    img: envelopeAlt,
  },
  'findus': {
    img: findus,
  },
  'findusalt': {
    img: findusAlt,
  },
  'freecharge': {
    img: nofee,
  },
  'freechargealt': {
    img: nofeeAlt,
  },
  'hamburger': {
    img: brokenBar,
  },
  'hand': {
    img: hand,
  },
  'handalt': {
    img: handAlt,
  },
  'headphones': {
    img: headphones,
  },
  'headphonesalt': {
    img: headphonesAlt,
  },
  'home': {
    img: home,
  },
  'homealt': {
    img: homeAlt,
  },
  'invest': {
    img: invest,
  },
  'investalt': {
    img: investAlt,
  },
  'lock': {
    img: lock,
  },
  'lockalt': {
    img: lockAlt,
  },
  'mobilelending': {
    img: mobilelending,
  },
  'mobilelendingalt': {
    img: mobilelendingAlt,
  },
  'mobilelocation': {
    img: mobilelocation,
  },
  'mobilelocationalt': {
    img: mobilelocationAlt,
  },
  'money': {
    img: money,
  },
  'moneyalt': {
    img: moneyAlt,
  },
  'monitor': {
    img: monitor,
  },
  'monitoralt': {
    img: monitorAlt,
  },
  'percentage': {
    img: percentage,
  },
  'percentagealt': {
    img: percentageAlt,
  },
  'piggy': {
    img: piggy,
  },
  'piggyalt': {
    img: piggyAlt,
  },
  'profile': {
    img: profile,
  },
  'profilealt': {
    img: profileAlt,
  },
  'shield': {
    img: shield,
  },
  'shieldalt': {
    img: shieldAlt,
  },
  'ship': {
    img: ship,
  },
  'shipalt': {
    img: shipAlt,
  },
  'traingle': {
    svg: dynamic(() => import('./CreditUnionIcon/Triangle').then(mod => mod.Triangle)),
  },
  'trainglealt': {
    svg: dynamic(() => import('./CreditUnionIcon/Triangle').then(mod => mod.Triangle)),
  },
  'times': {
    svg: dynamic(() => import('./CreditUnionIcon/TimesIcon').then(mod => mod.TimesIcon)),
  },
  'timesalt': {
    svg: dynamic(() => import('./CreditUnionIcon/TimesIcon').then(mod => mod.TimesIcon)),
  },
  'umbrella': {
    img: umbrella,
  },
  'umbrellaalt': {
    img: umbrellaAlt,
  },
  'unlock': {
    img: unlock,
  },
  'unlockalt': {
    img: unlockAlt,
  },
  'up': {
    img: up,
  },
  'upalt': {
    img: upAlt,
  },
  'visacard': {
    img: visacard,
  },
  'visacardalt': {
    img: visacardAlt,
  },
  'wallet': {
    img: wallet,
  },
  'walletalt': {
    img: walletAlt,
  },
  'wrench': {
    img: wrench,
  },
  'wrenchalt': {
    img: wrenchAlt,
  },
} as const

export type IconCodeType = keyof typeof CreditUnionIconMap

export type CreditUnionIconProps = React.ComponentProps<IconComponent> & {
  iconCode: IconCodeType
  variant?: boolean
}

export const CreditUnionIcon: React.FC<CreditUnionIconProps> = ({ iconCode, variant, svg, ...props }) => {
  if (variant) iconCode += 'alt'

  const Icon = CreditUnionIconMap[iconCode]

  if (Icon?.svg && (!Icon?.img || svg)) {
    return <Icon.svg { ...props as ComponentProps<typeof Icon.svg> } />
  }

  // eslint-disable-next-line @next/next/no-img-element
  return Icon?.img ? <img src={Icon.img} alt={''} { ...props as ComponentProps<ImgIconComponent> } /> : null
}
