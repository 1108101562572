import React, { ChangeEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Input, { InputProps } from '@material-ui/core/Input'
import { StyledSearchIcon, StyledSearchIconContainer } from './StyledSearchBarIcon'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { Icon } from '../icon/icon'
import { SearchBarProps } from './SearchBar'
import { useAutocomplete, useQuery } from '@sajari/react-hooks'
import { AutocompleteResults } from './AutocompleteResults'

export const DesktopSearchBar: React.FC<SearchBarProps> = ({ inputRef, onChange, onToggle, onSearchIcon, jumpTo, focus = false, sticky = false, ...props }) => {
  const { query } = useQuery()
  const { search } = useAutocomplete()
  const [value, setValue] = useState(query)

  useEffect(() => {
    if (!focus) {
      search('')
    }
  }, [focus]) // eslint-disable-line react-hooks/exhaustive-deps

  // State and Ref for toggle focus
  useEffect(() => {
    if (inputRef != null && focus) {
      inputRef.current.focus()
    }
  }, [focus, inputRef])

  return (
    <StyledSearchBarContainer container justifyContent="center" direction="row" {...props}>
      <Grid item>
        <StyledDesktopInputContainer as={'form'} action={'/search'} method={'get'}>
          <StyledDesktopInput
            inputRef={inputRef}
            $focus={focus}
            value={value}
            onChange={(e) => {
              search(e.target.value)
              setValue(e.target.value)

              if (onChange) {
                onChange(e as ChangeEvent<HTMLInputElement>)
              }
            }}
            tabIndex={focus ? 0 : -1}
            name={'q'}
            placeholder="Search..."
            inputProps={{ 'aria-label': 'description' }}
          />
          <StyledDesktopInputClose
            icon={faTimes}
            $focus={focus}
            onClick={onToggle}
          />
          {focus && (
            <AutocompleteResults setInput={setValue} />
          )}
        </StyledDesktopInputContainer>
      </Grid>
      <Grid item>
        <StyledSearchIconContainer $sticky={sticky} onClick={onSearchIcon}>
          {
            jumpTo?(
              <a href={`/search?q=${value}`} >
                <StyledSearchIcon $focus={focus} icon={faSearch} />
              </a>):<StyledSearchIcon $focus={focus} icon={faSearch} />
          }
        </StyledSearchIconContainer>
      </Grid>
    </StyledSearchBarContainer>
  )
}

export const StyledSearchBarContainer = styled(Grid)`
  width: auto;
  align-items: center;
`

export const StyledDesktopInputContainer = styled(Grid)`
  color: ${props => props.theme.palette.text.secondary};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledDesktopInputClose = styled(Icon)<{$focus: boolean}>`
  position: absolute;
  cursor: pointer;
  display: ${props => props.$focus ? 'block' : 'none'};
  font-size: ${props => props.theme.typography.pxToRem(20)};
  right: ${props => props.theme.typography.pxToRem(8)};
  opacity: 0.7;

  ${props => props.theme.breakpoints.up('sm')} {
    right: ${props => props.theme.typography.pxToRem(10)};
  }

  &:hover {
    color: ${props => props.theme.palette.primary.main};
  }
`

export const StyledDesktopInput = styled(Input)<{$focus: boolean} & InputProps>`
  width: ${props => props.$focus ? props.theme.typography.pxToRem(560) : 0};
  margin-right: ${props => props.theme.typography.pxToRem(10)};
  font-size: ${props => props.theme.typography.pxToRem(20)};
  opacity: 0.9;
  transition: width 0.3s ease-in-out;

  ${props => props.theme.breakpoints.down('md')} {
    margin-right: ${props => props.theme.typography.pxToRem(10)};
    width: ${props => props.$focus ? props.theme.typography.pxToRem(520) : 0};
  }

  & > input {
    padding-top: ${props => props.theme.typography.pxToRem(8)};
    padding-bottom: ${props => props.theme.typography.pxToRem(16)};
  }

  &::before {
    border-color: #707070;
    opacity: 0.5;
  }

  &::after {
    content: none;
  }
`