import React, { useCallback, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { Icon } from '../icon/icon'
import { SearchBarProps } from './SearchBar'
import { AutocompleteResults } from './AutocompleteResults'
import { useAutocomplete, useQuery } from '@sajari/react-hooks'
import { useRouter } from 'next/router'

export type MobileSearchBarProps = SearchBarProps & {
  isOpen?: boolean
}

export const MobileSearchBar: React.FC<MobileSearchBarProps> = ({ inputRef, focus, isOpen, unfocus }) => {
  const [inputState, setInput] = useState(false)
  const router = useRouter()
  const { search } = useAutocomplete()
  const { query, setQuery } = useQuery()
  const [showAutocomplete, setShowAutocomplete] = useState(false)
  const [value, setValue] = useState(query)

  useEffect(() => {
    if (inputRef != null) {
      inputRef.current.focus()
    }
  }, [focus, inputRef])

  const onChange = (event) => {
    search(event.target.value)
    setValue(event.target.value)

    if (router?.route === '/search') {
      setQuery(event.target.value)
    }

    if (event.target.value === '') {
      setShowAutocomplete(false)
      setInput(false)
    }
    else {
      setShowAutocomplete(true)
      setInput(true)
    }
  }

  const onBlur = useCallback(() => {
    setTimeout(() => {
      setShowAutocomplete(false)

      if (unfocus) {
        unfocus()
      }
    }, 100)
  }, [unfocus])

  const handleSubmit = ()=>{
    if (router?.route !== '/search') {
      window.location.href = `/search?q=${inputRef?.current.value}`
    }
  }

  return (
    <StyledSearchContainer $isOpen={isOpen} as={'form'} action={'/search'} method={'GET'}>
      <StyledMobileTextField
        inputRef={inputRef}
        $focus={focus}
        name={'q'}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        placeholder="Search..."
        inputProps={{ 'aria-label': 'description' }}
      />
      <StyledMobileSearchIcon onClick={handleSubmit} icon={faSearch} $hasText={inputState}/>
      {showAutocomplete && (
        <AutocompleteResults setInput={setValue} />
      )}    
    </StyledSearchContainer>
  )
}

const StyledSearchContainer = styled(Grid)<{$isOpen?: boolean}>`
  background-color: rgba(242, 242, 242, 0.8);
  padding: ${props => props.theme.typography.pxToRem(14)} ${props => props.theme.typography.pxToRem(16)} ${props => props.theme.typography.pxToRem(14)} ${props => props.theme.typography.pxToRem(16)};
  position: absolute;
  width: 100%;
  top: ${props => props.theme.typography.pxToRem(72)};
  left: 0;
  opacity: ${props => props.$isOpen ? 1 : 0};
  transform: translateY(${props => props.$isOpen ? '0' : props.theme.typography.pxToRem(-10)});
  transition: transform 0.3s, opacity 0.2s ease-in-out;
`

export const StyledMobileTextField = styled(Input)<{ $focus?: boolean}>`
  background-color: ${props => props.theme.palette.common.white};
  width: 100%;
  border-radius: 4px;
  border: black 1px;
  padding: ${props => props.theme.typography.pxToRem(10)};
  font-size: ${props => props.theme.typography.pxToRem(16)};

  input {
    padding-right: ${props => props.theme.typography.pxToRem(60)};
  }

  &::before {
    content: none;
  }

  &::after {
    content: none;
  }
`

const StyledMobileSearchIcon = styled(Icon)<{ $hasText?: boolean }>`
  position: absolute;
  right: ${props => props.theme.typography.pxToRem(26)};
  color: ${props => props.theme.palette.primary.main};
  font-size: ${props => props.theme.typography.pxToRem(16)};
  top: 50%;
  transform: translate(0, -50%);

  ${props => props.$hasText && css`
    right: ${props => props.theme.typography.pxToRem(26)};
  `}
`

