import React, { ChangeEventHandler, MutableRefObject, useContext, useRef, useState, useEffect } from 'react'
import { DesktopSearchBar } from './DesktopSearchBar'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid'
import { MobileSearchBarContainer } from './MobileSearchBarContainer'
import { PrimaryNavigationContext } from '../PrimaryNavigation/PrimaryNavigation'

export type SearchBarProps = React.ComponentProps<typeof Grid> & {
  onSearchIcon?: () => void
  onToggle?: () => void
  onClear?: () => void
  unfocus?: () => void
  onChange?: ChangeEventHandler<HTMLInputElement>
  jumpTo?: boolean
  focus?: boolean
  inputRef?: React.MutableRefObject<HTMLInputElement>
  sticky?: boolean
  secondary?: boolean
}

export const SearchBar: React.FC<SearchBarProps> = ( props ) => {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'))
  const [focus, setFocus] = useState(false)
  const [jumpTo, setJumpTo] = useState(false)
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>
  const { searchIsOpen, setSearchIsOpen } = useContext(PrimaryNavigationContext)
  const clearInput = () => {
    inputRef.current.value = ''
  }

  const onToggleDesktop = () => {
    setFocus(!focus)
    searchIsOpen ? setSearchIsOpen(false) : setSearchIsOpen(true)
  }

  const onSearchIcon = () => {
    setFocus(true)
    focus? setJumpTo(true) : setJumpTo(false)
  }

  useEffect(() => {
    focus ? setSearchIsOpen(true) : setSearchIsOpen(false)
  })

  useEffect(() => {
    focus ? setJumpTo(true) : setJumpTo(false)
  },[focus])

  const onToggleMobile = () => {
    setFocus(!focus)
  }

  return(
    <React.Fragment>
      {isTablet
        ? <DesktopSearchBar inputRef={inputRef} onClear={clearInput} focus={focus} onToggle={onToggleDesktop} onSearchIcon={onSearchIcon} jumpTo={jumpTo} {...props} />
        : <MobileSearchBarContainer inputRef={inputRef} focus={focus} onToggle={onToggleMobile} {...props} />
      }
    </React.Fragment>
  )
}